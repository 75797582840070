<template>
  <main class="presentation-page">
    <section class="presentation-page__header">
      <BreadcrumbsComponent :links="breadcrumbs" />
      <div class="presentation-page__header-wrapper">
        <h1 class="presentation-page__title">Презентация</h1>
        <a
          target="_blank"
          href="/static/files/ComradesDevs.pdf"
          download="ComradesDevs"
          class="btn btn--white"
        >
          <IconComponent name="presentation" />
          <span>Скачать презентацию</span>
        </a>
      </div>
    </section>
    <section class="presentation-page__body">
      <ImgComponent v-if="loading" src="static/svg/logo.svg" class="presentation-page__loading" />
      <ClientOnly>
        <iframe
          v-if="showFrame"
          ref="frame"
          :src="`https://docs.google.com/gview?url=https://comrades.dev/static/files/ComradesDevs.pdf&embedded=true`"
          class="presentation-page__iframe"
          :class="{ 'presentation-page__iframe--loaded': frameIsLoaded }"
          @load="frameLoaded"
        />
      </ClientOnly>
    </section>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import ClientOnly from "vue-client-only";
import ImgComponent from "components/ImageComponent.vue";

export default {
  name: "PresentationPage",
  components: { ImgComponent, IconComponent, BreadcrumbsComponent, ClientOnly },
  data() {
    return {
      frameDebounce: null,
      frameIsLoaded: false,
      showFrame: false,
      loading: true,
      breadcrumbs: [
        {
          route: {
            name: "presentation",
          },
          title: "Презентация",
        },
      ],
    };
  },
  /** Осторожно тут очень жестокие костыли, опасно для здоровья!!! **/
  mounted() {
    this.showFrame = true;
    this.checkFrame();
  },
  beforeDestroy() {
    clearInterval(this.frameDebounce);
  },
  methods: {
    frameLoaded() {
      this.loading = false;
      this.frameIsLoaded = true;
    },
    checkFrame() {
      this.frameDebounce = setInterval(() => {
        if (this.frameIsLoaded) {
          clearInterval(this.frameDebounce);
        } else {
          this.showFrame = false;
          this.$nextTick(() => {
            this.showFrame = true;
          });
        }
      }, 1500);
    },
  },
  metaInfo: {
    title: "Презентация",
  },
};
</script>

<style scoped lang="stylus">
.presentation-page {

  &__header {
    display: flex;
    flex-direction: column;
    padding: 32px 100px 60px;
    gap 60px
    background: var(--gray-1);
    width: 100%;
    +below(1400px) {
      padding: 32px 80px 15px;
    }
    +below(1100px) {
      padding: 32px 15px 15px;
    }

    &-wrapper {
      display flex
      justify-content space-between
      align-items center

      +below(628px) {
        flex-direction column
        gap 16px
        align-items flex-start

        & .btn {
          //width 100%
        }
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 3.75rem;
    line-height: 71px;
    color: var(--blue);
    margin 0
    padding 0

    +below(1100px) {
      font-size: 1.875rem;
      line-height: 35px;
    }
  }

  &__body {
    padding: 32px 100px 60px;
    min-height: 80vh

    +below(1400px) {
      padding: 32px 80px 15px;
    }

    +below(1100px) {
      padding: 32px 15px 15px;
    }
  }

  &__iframe {
    border none
    height 80vh
    width 100%
    border-radius 10px
    opacity 0
    transition var(--transition)
    //+below(1024px) {
    //  height 100vw
    //}

    &--loaded {
      opacity: 1;
    }
  }

  &__loading {
    display flex
    width 80px
    margin: 0 auto

    +below(628px) {
      width: 45px
    }
  }
}
</style>
